class Formatter {
  static date = (value: string): string => {
    if (!value) {
      return '';
    }
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  static price = (value: number, removeCents? : boolean): string => {
    var options: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: 'USD'
    };
    if (removeCents ?? false) {
      options.minimumFractionDigits = 0;
      options.maximumFractionDigits = 0;
    }
    const formatter = new Intl.NumberFormat('en-US', options);
    return formatter.format(value);
  }

  static quantity = (value: number, transaction_code: string | undefined): string => {
    return `${value > 0 && transaction_code != 'C' ? '+' : ''}${value.toLocaleString()}`;
  }

  static owned = (value: number): string => {
    return `${Math.round(value).toLocaleString()}`;
  }

  static change = (value: number, decimalPlaces?: number): string => {
    if (decimalPlaces) {
    return `${Number(value.toFixed(decimalPlaces)).toLocaleString()}%`
    }
    return `${value.toLocaleString()}%`;
  }

  static value = (value: number, transaction_code: string | undefined): string => {
    // todo: use stock price
    if (Math.abs(value) >= 1_000_000_000) {
      return 'Check filing';
    }
    const format = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
    const prefix = value > 0 && transaction_code != 'C' ? '+' : '';
    return `${prefix}${format.format(value)}`.trim();
  }

  static marketCap = (value: number | null | undefined): string => {
    if (value == null || value == undefined) {
      return '';
    }
    const marketCapInMillions = value / 1_000_000;
    const formattedMarketCap = Math.round(marketCapInMillions).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'M';
    return formattedMarketCap;
  }
}

export default Formatter;
